import { AddressComponent, Place } from "../../types";

export const countryCheck = (addressComponents: AddressComponent[]) => {
    if(addressComponents.filter((c) => c.short_name === ("GB")).length === 0){
        return false;
    } 
    return true;
}

export const countryCheckForMapRoute = (origin: Place | null, destination: Place | null) => {
    if(origin !== null && destination !== null){
        if(origin?.addressComponents.filter((c) => c.short_name === ("GB")).length === 0){
            return false;
        }
        if(destination?.addressComponents.filter((c) => c.short_name === ("GB")).length === 0){
            return false;
        }
        return true;
    }
    return false;
}
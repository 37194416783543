import { Box, Grid, Typography } from "@mui/material";
import { TripRoute } from "../types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoute, faClock, faMoneyBill } from '@fortawesome/free-solid-svg-icons'

interface Props {
    tripRoute: TripRoute;
}

const JourneyBar = ({tripRoute}: Props) => {

    const Distance = () => (
        tripRoute.distance !== null ? (<>{tripRoute.distance?.toFixed(1)} miles</>) : ("Distance")
    );

    const Duration = () => (
        tripRoute.duration !== null ? (<>{tripRoute.duration}</>) : ("Duration")
    );

    const Cost = () => (
        tripRoute.cost !== null ? (<>£ {tripRoute.cost.toFixed(2)}</>) : ("Cost")
    );

    return(
        <Box sx={{height: '65px', color: "#fff", backgroundColor: "#353935", padding: 2, borderRadius: "0px 0px 4px 4px"}}>
            <Grid container sx={{height: "60px"}}>
                <Grid item xs={4}>
                    <Box sx={{borderRadius: 2, height: "100px"}}>
                        <FontAwesomeIcon icon={faRoute} fontSize={28} />
                        <Typography sx={{fontWeight: 500, fontSize: "16px"}}>
                            {Distance()}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{borderRadius: 2, height: "100px"}}>
                        <FontAwesomeIcon icon={faClock} fontSize={28}/>
                        <Typography sx={{fontWeight: 500, fontSize: "16px"}}>
                            {Duration()}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{color: "#4b4b4b", backgroundColor: "#FDB813", borderRadius: 2, height: "70px", marginTop: "-5px", paddingTop: "5px"}}>
                        <FontAwesomeIcon icon={faMoneyBill} fontSize={28} style={{color: "#000"}}/>
                        <Typography sx={{fontWeight: 500, fontSize: "18px", color: "#000"}}>
                            {Cost()}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}   

export default JourneyBar;
import { Box } from "@mui/material";
import Quote from "./quote/Page";

const Page = () => {
    return(
        <Box sx={{maxWidth: "1130px", margin: "auto"}}>
            <Quote />
        </Box>
    )
}

export default Page;
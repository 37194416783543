import { Box, AppBar, Typography, Grid, Link } from "@mui/material";
import Logo from "../assets/maq_cars_logo.png";
import "@fontsource/khula";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faMapPin } from '@fortawesome/free-solid-svg-icons'

const Header = () => {
    
    return(
        <Box sx={{ flexGrow: 1, height: "150px" }} >
        <AppBar elevation={0} sx={{backgroundColor: "#fff"}}>

          <Box position="static" sx={{height: 97, backgroundColor: "#202020"}}>
            <Grid container spacing={0} sx={{paddingTop: "15px"}}>
                <div style={{float: "left", fontFamily: "Khula", fontSize: "15px", margin: "auto", width: "1130px"}}>
                    <div style={{float: "left"}}>
                        <span style={{fontSize: 16, paddingTop: "10px"}}>
                        <FontAwesomeIcon icon={faEnvelope} color="#FDB813"/>
                        </span>
                        <span style={{paddingLeft: "10px"}}>
                            <a style={{textDecoration: "none", color: '#fff'}} href="mailto:contact@maqcars.co.uk">contact@maqcars.co.uk</a>
                        </span>
                        <span style={{fontSize: 16, paddingTop: "10px", paddingLeft: "15px"}}>
                        <FontAwesomeIcon icon={faPhone} color="#FDB813"/>
                        </span>
                        <span style={{paddingLeft: "15px"}}>
                            <a style={{textDecoration: "none", color: '#fff'}} href="tel:+447792662861">(+44) 7792662861</a>
                        </span>
                        <span style={{fontSize: 16, paddingTop: "10px", paddingLeft: "15px"}}>
                        <FontAwesomeIcon icon={faMapPin} color="#FDB813"/>
                        </span>
                        <span style={{paddingLeft: "15px"}}>
                            Sheffield, South Yorkshire
                        </span>

                    </div>
                </div>
            </Grid>
          </Box>

          <div style={{margin: "auto", maxWidth: "1130px"}}>
          <Grid sx={{
                color: "black", 
                backgroundColor: '#fff', 
                height: "80px", 
                width: "1130px", 
                margin: "0",
                marginTop: "-44px",
                borderRadius: "15px",
                boxShadow: "0px 4px 5px 0px rgba(148,148,148,1)"
            }}>
                <Grid container spacing={2} sx={{marginTop: 0}}>
                    <Grid item xs={3}>
                        <div style={{maxWidth: "170px", paddingLeft: "25px", paddingTop: "5px"}}>
                            <img onClick={() => {window.location.href = "https://maqcars.co.uk"}} src={Logo} width="160"/>
                        </div>
                    </Grid>

                    <Grid item xs={7}>

                        <Grid container sx={{marginTop: "10px", paddingTop: "7px", paddingLeft: "25px"}} >
                            <Grid item xs={3}/>
                            <Grid item xs={6}>
                                <Grid container sx={{marginLeft: -6}}>
                                    <Grid item xs={3} sx={{fontSize: "16px"}}>
                                        <Link href="https://maqcars.co.uk/chauffeur/" sx={{textDecoration: "none"}}>
                                            <Typography sx={{fontFamily: "Khula", color: "#6D6D6D",  fontSize: "16px"}}>Chauffeur</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6} sx={{fontSize: "16px", paddingLeft: 3}}>
                                        <Link href="https://maqcars.co.uk/private-hire/" sx={{textDecoration: "none"}}>
                                            <Typography sx={{fontFamily: "Khula", color: "#6D6D6D"}}>Private hire</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={3} sx={{fontSize: "16px", paddingLeft: 4}}>
                                        <Link href="https://maqcars.co.uk/contact/" sx={{textDecoration: "none"}}>
                                            <Typography sx={{fontFamily: "Khula", color: "#6D6D6D"}}>Contact</Typography>
                                        </Link> 
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}/>
                        </Grid>



                    </Grid>
                    <Grid item xs={2} sx={{marginTop: "-5px"}}>
                        <button style={{fontSize: "15px", fontFamily: "Khula", marginRight: "28px", float: "right", height: "60px", width: "137px", backgroundColor: "#FDB813", border: 0, borderRadius: 10, fontWeight: "600"}}>
                            Get a quote
                        </button>
                    </Grid>
                </Grid>
            </Grid>

            </div>

        </AppBar>
      </Box>
    )

}


export default Header;
import { Skeleton, Box, FormLabel, Grid, InputAdornment, TextField } from "@mui/material";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { useState } from "react";
import { TripRoute } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from '@fortawesome/free-solid-svg-icons'
import { PlaceMapper } from "../../utils/quote/PlaceMapper";
import { useFormContext } from "react-hook-form";
import { countryCheck } from "../../utils/quote/CountryCheck";

interface Props {
    gMapsApiStatus: boolean;
    setTripRoute(route: TripRoute) : void;
    tripRoute: TripRoute;
}

const PickupField = ({gMapsApiStatus, setTripRoute, tripRoute}: Props) => {
    
    const { register, formState: { errors }, setError, setValue } = useFormContext();

    const [pickupRef, setPickupRef] = useState<any>();
    const onPickupLoad = (ref: any) => setPickupRef(ref);

    const onPickupChanged = () => {
        if(pickupRef !== undefined){
            const pickupLoc = pickupRef.getPlaces();
            if(!countryCheck(pickupLoc[0].address_components)){
                setError("pickup", { type: 'required', message: 'Location must be within the UK' });
                setValue("pickup", "");
            }else{
                setTripRoute({
                    ...tripRoute,
                    distance: null,
                    duration: null,
                    cost: null,
                    origin: PlaceMapper(pickupLoc[0])
                });
            }
        }
    }


    return(
            <Grid item xs={12}>
                <Box sx={{width: '100%', textAlign: 'left', marginTop: "5px"}}>
                    <FormLabel>Pick up point</FormLabel>
                </Box>
                    {gMapsApiStatus ?
                        <StandaloneSearchBox
                            onLoad={onPickupLoad}
                            onPlacesChanged={onPickupChanged}
                            bounds={
                                new google.maps.LatLngBounds(
                                    new google.maps.LatLng(49.906404, -5.744317),
                                    new google.maps.LatLng(55.722192, 2.340094)
                                )
                            }
                        >
                            <TextField 
                                sx={{width: "100%"}} 
                                {...register("pickup", { required: 'Required'})}
                                error={errors.pickup !== undefined}
                                placeholder="Post code or address"
                                helperText={errors.pickup?.message !== undefined ? errors.pickup.message.toString() : ""}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        <FontAwesomeIcon icon={faMapPin}/>
                                      </InputAdornment>
                                    ),
                                }}
                            />   
                        </StandaloneSearchBox>
                    : 
                        <Skeleton sx={{height: "56px"}}/>
                    }
            </Grid>
    )
}

export default PickupField;
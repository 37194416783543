import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { Vehicle, VehicleType } from "../../types";
import SaloonCarIcon from "../../assets/saloon_car.png";
import EstateCarIcon from "../../assets/estate_car.png";
import ExecCarIcon from "../../assets/exec_car.png";
import SaloonCarSelectedIcon from "../../assets/saloon_car_selected.png";
import EstateCarSelectedIcon from "../../assets/estate_car_selected.png";
import ExecCarSelectedIcon from "../../assets/exec_car_selected.png";
import { useWatch, useFormContext } from "react-hook-form";


const imgUriResolver = (vehicleType: string, selected: boolean) => {
    switch(vehicleType){
        case "saloon":
            if(selected) return SaloonCarSelectedIcon;
            else return SaloonCarIcon;
        case "estate":
            if(selected) return EstateCarSelectedIcon;
            else return EstateCarIcon;
        case "executive":
            if(selected) return ExecCarSelectedIcon;
            else return ExecCarIcon;
    }
}

interface Props {
    vehicle: Vehicle;
    selected: boolean;
    setSelected(vehicle: Vehicle | null): void;
    selectedLuggageNo: number;
}


const VehicleButton = ({vehicle, selected, setSelected, selectedLuggageNo}: Props) => {

    return(
        <button 
            style={{backgroundColor: selected ? "#FDB813" : "#fff", border: "1px solid lightgray", borderRadius: 4}}
            onClick={() => setSelected(vehicle)} 
            type="button"
            disabled={selectedLuggageNo > vehicle.luggageCapacity}
        >
            <Box>
                <img src={imgUriResolver(vehicle.type, selected)} width="95"/>
                <Typography sx={{marginTop: "-23px"}}>{vehicle.name}</Typography>
            </Box>
        </button>
    )
}

export default VehicleButton;
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TripRoute } from '../types';
import { EndpointProvider } from '../utils/EndpointProvider';
import { useFormContext } from 'react-hook-form';
import useAxios from 'axios-hooks';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress, Typography } from '@mui/material';


interface Props {
    open: boolean;
    handleClose: () => void;
    tripRoute: TripRoute;
}

const BookingReqDialog = ({open, handleClose, tripRoute}: Props) => {

    const { getValues, control } = useFormContext();

    const [email, setEmail] = React.useState<string>("");
    const [emailError, setEmailError] = React.useState<boolean>(false);
    const [phone, setPhone] = React.useState<string>("");
    const [phoneError, setPhoneError] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");
    const [nameError, setNameError] = React.useState<boolean>(false);
    const [requestedBooking, setRequestedBooking] = React.useState<boolean>(false);


    const endpointUrl = EndpointProvider("api/bookingRequest");

    const formValues = getValues();

    const [{data, loading, error}, requestBooking] = useAxios<any>(
        {
        url: endpointUrl,
        data: { 
            ...tripRoute,
            ...formValues,
            customerEmail: email,
            customerName: name,
            customerPhone: phone
        },
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
        },        
        withCredentials: true,
        },
        { manual: true } 
    );


    const handleRequest = () => {
        if(email === ""){
            setEmailError(true);
        }else if(phone === ""){
            setPhoneError(true);
        }else if(name === ""){
            setNameError(true)
        }else{
            requestBooking().then((res) => {
                setRequestedBooking(true)
            })
        }
    }

    const hangleEmailChange = (val: string) => {
        if(val !== "") setEmailError(false);
        setEmail(val);
    }

    const handleNameChange = (val: string) => {
        if(val !== "") setNameError(false);
        setName(val);
    }

    const handlePhoneChange = (val: string) => {
        if(val !== "") setPhoneError(false);
        setPhone(val);
    }

    const handleResetRequest = () => {
        setRequestedBooking(false);
        handleClose();
    }


  return (
    
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Booking request</DialogTitle>
        <DialogContent>
            {loading ? <LinearProgress /> : null}
            {requestedBooking ?
                <div style={{textAlign: "center"}}>
                    <FontAwesomeIcon icon={faCheckCircle} fontSize={100} style={{color: "green"}}/>
                    <div style={{marginTop: "25px"}}>
                        <Typography>We have received your request and will be in touch soon.</Typography>
                        <Typography>Thanks!</Typography>
                    </div>
                </div>
            :
            <>

            <div>
                You are about to request a booking from <b>{tripRoute.origin?.formattedAddress}</b> to <b>{tripRoute.destination?.formattedAddress}</b> for <b>{formValues.date} and {formValues.time}</b>.
            </div>
            <div style={{marginTop: "5px"}}>
            The cost of the trip will be <b>£{tripRoute.cost?.toFixed(2)}</b> (to be confirmed) and will take approximately <b>{tripRoute.duration}</b>.
            </div>
            <div style={{marginTop: "5px"}}>
                Please enter your contact details and we will respond as soon as possible.
            </div>
            
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Full name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => handleNameChange(e.target.value)}
            defaultValue={name}
            error={nameError}
            helperText={nameError ? "Required" : ""}
            required
          />
          <TextField
            autoFocus
            margin="dense"
            defaultValue={email}
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            sx={{marginTop: 2}}
            onChange={(e) => hangleEmailChange(e.target.value)}
            error={emailError}
            helperText={emailError ? "Required" : ""}
            required
          />
          <TextField
            autoFocus
            margin="dense"
            id="phone"
            defaultValue={phone}
            label="Phone number"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => handlePhoneChange(e.target.value)}
            required
            error={phoneError}
            helperText={phoneError ? "Required" : ""}
          />
            </>
            }
        </DialogContent> 
        
        <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {!requestedBooking ?
          <Button onClick={handleRequest}>Request booking</Button>
        :
            <Button onClick={handleResetRequest}>Make another request</Button>
        }
        </DialogActions> 
      </Dialog>
    </div>
  );
}

export default BookingReqDialog;
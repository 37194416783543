import { InputAdornment, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLuggageCart } from '@fortawesome/free-solid-svg-icons'


const LuggageNumberField = () => {
    const { register } = useFormContext();

    return(
            <TextField
                label={"Number of luggage"}
                type={"number"}
                defaultValue={0}
                sx={{width: "100%"}}
                {...register("luggage")}
                    InputProps={{
                        inputProps: { 
                            max: 4, min: 0
                        },
                    endAdornment: (
                        <InputAdornment position='end'>
                            <FontAwesomeIcon icon={faLuggageCart}/>
                        </InputAdornment>
                    )
                }}
            />
    )

}

export default LuggageNumberField;
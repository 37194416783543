import { Card,Grid, Box } from "@mui/material";
import Form from "./Form/Form";
import JourneyBar from "./JourneyBar";
import Map from "./Map";
import { useScript } from "../ExternalScriptProvider";
import { FormValues, IGMapsApiStatus, TripRoute } from "../types";
import { useForm, FormProvider} from "react-hook-form";
import { useState } from "react";
import BookingReqDialog from "./BookingReqDialog";
import isDevEnv from "../utils/DevDetect";


const initRoute: TripRoute = {
    origin: null,
    destination: null,
    distance: null,
    duration: null,
    cost: null
}


const Quote = () => {
    const methods = useForm<FormValues>();

    const apiKey = isDevEnv() ? "AIzaSyDtkOLbQtJtZxfSFuwKnllGMlMeb-7IiN0" : "AIzaSyDHgvtbv9JOjk41XQ1f4Pg0qMQ_cWU-Of0";

    const GMapsApiStatus: IGMapsApiStatus = useScript(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
    );

    const [tripRoute, setTripRoute] = useState<TripRoute>(initRoute);
    const [openBookingRequest, setOpenBookingRequest] = useState<boolean>(false);

    const showBookingButton = () => tripRoute.cost !== null && tripRoute.cost > 10;

    return(
        <>
        <Card sx={{paddingLeft: 5, paddingRight: 5, backgroundColor: '#fff', paddingBottom: 2, paddingTop: 0}}>
            <Box sx={{textAlign: "left", marginTop: "-3px"}}>
                <h3>Journey details</h3>
            </Box>
            <Grid container spacing={0} sx={{flexDirection: { xs: "column", md: "row"}}}>
            <FormProvider {...methods} > 
            
                <Grid item md={5} sx={{marginBottom: 1}}>
                    <Box sx={{}}>
                        <Form 
                            setTripRoute={setTripRoute} 
                            tripRoute={tripRoute}
                            gMapsApiStatus={GMapsApiStatus.status === "ready"} 
                        />
                    </Box>
                </Grid>
                <Grid item md={7}>
                    <div style={{width: "98%", marginLeft: "1%"}}>
                        <Map 
                            origin={tripRoute.origin}
                            destination={tripRoute.destination}
                            gMapsApiStatus={GMapsApiStatus.status === "ready"} 
                        />
                        <JourneyBar tripRoute={tripRoute} />
                    </div>
                </Grid>


                {showBookingButton() ?
                <Box sx={{width: "100%", marginTop: 1}}>
                    <button 
                        type="button"
                        onClick={() => setOpenBookingRequest(true)}
                        form="journey-form"
                        style={{width: "100%", color: "white", fontSize: "15px", fontFamily: "Khula", height: "60px", backgroundColor: "#2A2A2A", border: 0, borderRadius: 10, fontWeight: "600"}}>
                        Request booking
                    </button>
                </Box> : null }
                <Box sx={{width: "100%", marginTop: 1}}>
                    <button 
                        type="submit" 
                        form="journey-form"
                        style={{width: "100%", fontSize: "15px", fontFamily: "Khula", height: "60px", backgroundColor: "#FDB813", border: 0, borderRadius: 10, fontWeight: "600"}}>
                        Calculate price
                    </button>
                </Box>


            <BookingReqDialog open={openBookingRequest} handleClose={() => setOpenBookingRequest(false)} tripRoute={tripRoute} />
            </FormProvider>
            </Grid>
        </Card>
        </>
    )
}

export default Quote;
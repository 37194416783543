import { Button, Grid, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { Vehicle, VehicleType } from "../../types";
import VehicleButton from "./VehicleButton";
import { useFormContext, useWatch } from "react-hook-form";
import useAxios from "axios-hooks";
import { EndpointProvider } from "../../utils/EndpointProvider";


const VehicleSelect = () => {

    const { setValue, control } = useFormContext();

    const endpointUrl = EndpointProvider("api/vehicles");
    const [{ data, loading, error }] = useAxios<any>(
        {
            url: endpointUrl,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }
    );

    const selectedLuggageNo = useWatch({
        control,
        name: "luggage"
    });


    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);
    
    useEffect(() => {
        if(data){
            const saloon = data.vehicles.filter((v: Vehicle) => v.type === "saloon")[0];
            setSelectedVehicle(saloon);
            setValue("vehicleId", saloon?.id);
        }
    }, [data])

    useEffect(() => {
        setValue("vehicleId", selectedVehicle?.id);
    }, [selectedVehicle])

    useEffect(() => {
        if(data && selectedVehicle !== null){
            if(selectedVehicle.luggageCapacity < selectedLuggageNo){
                 const availableVehicle = data.vehicles.filter((v: Vehicle) => v.luggageCapacity >= selectedLuggageNo)[0];
                 if(availableVehicle !== undefined){
                    setSelectedVehicle(availableVehicle);
                 }
            }

        }

    }, [selectedLuggageNo])


    if(loading){
        return(
            <Skeleton />
        )
    }

    if(data){
        return(
            <>
                {data.vehicles.map((vehicle: Vehicle) => {
                    return(
                        <Grid item sx={{marginRight: 2, marginTop: 1}} key={vehicle.id}>
                            <VehicleButton 
                                vehicle={vehicle}
                                selected={(selectedVehicle !== null) && selectedVehicle.id === vehicle.id} 
                                setSelected={setSelectedVehicle}
                                selectedLuggageNo={selectedLuggageNo}
                            />
                        </Grid>
                    )
                })}
            </>
        )
    }

    return (<></>);

}

export default VehicleSelect;
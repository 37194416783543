import { LastSubmissionData } from "../../quote/Form/Form";

export const hasSubmissionDataChanged = (lastSubmission: LastSubmissionData | null, formData: any) =>{
    if(lastSubmission !== null){
        if( (lastSubmission.date !== formData.date)
            || (lastSubmission.vehicleId !== formData.vehicleId)
            || (lastSubmission.pickup !== formData.pickup)
            || (lastSubmission.dropoff !== formData.dropoff)){
                return true;
        }else return false;
    }else return true;
}

export const hasLocationDataChanged = (lastSubmission: LastSubmissionData | null, formData: any) =>{
    if(lastSubmission !== null){
        if((lastSubmission.pickup !== formData.pickup)
            || (lastSubmission.dropoff !== formData.dropoff)){
                return true;
        }else return false;
    }else return true;
}

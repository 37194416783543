import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { TripRoute } from "../../types";

interface Props {
    setTripRoute(route: TripRoute) : void;
    tripRoute: TripRoute;
}

const DateField = ({tripRoute, setTripRoute}: Props) => {
    const [date, setDate] = useState<Dayjs | null>(null);
    const { register, formState: { errors }, setValue } = useFormContext();
    

    const onChange = (val: Dayjs | null) => {
        if(val !== null){
            setDate(val);
            const formattedDate = moment(dayjs(val).toDate()).format("DD/MM/YYYY");
            setValue("date", formattedDate);
            setTripRoute({
                ...tripRoute,
                cost:null
            });
        }
    }

    return(
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Date"
                    value={date}
                    onChange={onChange}
                    renderInput={(params) =>
                        <TextField 
                            {...params} 
                            fullWidth={true}
                            {...register("date", { required: true })}
                            error={errors.date !== undefined}
                            helperText={errors.date?.message !== undefined ? "Required" : ""}
                        />
                    }
                    inputFormat="DD/MM/YYYY"
                />
            </LocalizationProvider>
    )
}

export default DateField;
import moment from "moment";
import { TripRoute } from "../../types";

export const CalculateMatrix = async (dateVal: string, tripRoute: TripRoute) => {
    var service = new window.google.maps.DistanceMatrixService();

    const date = moment(dateVal).format('MM/DD/YYYY');
    const departureDate = new Date(date.toString());

    var matrixRequest = {
       destinations: [
           new google.maps.LatLng(
            tripRoute.destination!.location.latitude!, 
            tripRoute.destination!.location.longitude, 
           )
       ],
       origins: [
           new google.maps.LatLng(
            tripRoute.origin!.location.latitude, 
            tripRoute.origin!.location.longitude, 
           )
       ], 
       travelMode: google.maps.TravelMode.DRIVING,
       transitOptions: {
        departureTime: departureDate
       }
    }

    const matrix = await service.getDistanceMatrix(matrixRequest);

    return {
        distance: matrix.rows[0].elements[0].distance.value * 0.000621371,
        duration: matrix.rows[0].elements[0].duration.text
    }
}
import { InputAdornment, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons'


const PassengersNumberField = () => {
    const { register } = useFormContext();

    return(
        <TextField
            label={"Number of passengers"}
            type={"number"}
            defaultValue={1}
            sx={{width: "100%", backgroundColor: '#fff'}}
            {...register("passengers")}
            InputProps={{
                inputProps: { 
                    max: 4, min: 1 
                },
                endAdornment: (
                    <InputAdornment position='end'>
                        <FontAwesomeIcon icon={faUser}/>
                    </InputAdornment>
                )
            }}
        />

    )

}


export default PassengersNumberField;
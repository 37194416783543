import React, { useEffect } from "react";
import { Place, TripRoute } from "../types";

import { GoogleMap, DirectionsRenderer, DirectionsService, StreetViewPanorama } from '@react-google-maps/api';
import { Skeleton } from "@mui/material";
import { countryCheckForMapRoute } from "../utils/quote/CountryCheck";

interface Props {
    gMapsApiStatus: boolean;
    origin: Place | null;
    destination: Place | null;
}

const containerStyle = {
    width: '100%',
    height: '337px'
};

interface PreviousRoute {
    origin: Place | null;
    destination: Place | null;
}

interface Center {
    lat: number;
    lng: number;
}

const Map = ({gMapsApiStatus, origin, destination}: Props) => {

    const originalCenter = {
        lat: 53.37055,
        lng: -1.48870
    };
      
      const [directions, setDirections] = React.useState<any>(null);
      const [previousRoute, setPreviousRoute] = React.useState<PreviousRoute>();
      const [center, setCenter] = React.useState<Center>(originalCenter);

      const drawRoute = () => {
        if(gMapsApiStatus){
            const DirectionsService = new google.maps.DirectionsService();
            DirectionsService.route(
              {
                  origin: origin?.formattedAddress!,
                  destination: destination?.formattedAddress!,
                  travelMode: google.maps.TravelMode.DRIVING,
              }
            ).then((res) => {
                  setDirections(res);
            }).catch((err) => {
                //TODO: proper handling if first search
            });
        }
      }


      useEffect(() => {
        if(origin !== previousRoute?.origin || destination !== previousRoute?.destination){
            if(countryCheckForMapRoute(origin, destination)){
                drawRoute();
                setPreviousRoute({origin: origin, destination: destination});
            }
        }else if(origin !== null && destination !== null){
            const newLat = destination.location.latitude;
            const newLng = destination.location.longitude;
            setCenter({ lat: newLat, lng: newLng});
        }
      },[origin, destination])

    
      return (
        <>
        {gMapsApiStatus ? (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={8}
                options={
                    {
                        streetViewControl: false, 
                        disableDefaultUI: true
                    }
                }
            >
                <DirectionsRenderer
                    directions={directions}
                />
            </GoogleMap>
        ) : 
            <Skeleton height={300} />
        }
        </>
      );

}


export default Map;
import { Box, AppBar, Typography, Grid, IconButton, Link } from "@mui/material";
import Logo from "../assets/maq_cars_logo.png";
import "@fontsource/khula";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";

const MobileHeader = () => {

    const [showMenu, setShowMenu] = useState<boolean>(false);
    
    return(
        <Box sx={{ flexGrow: 1, height: "100px" }} >
        <AppBar position="fixed" elevation={0} sx={{backgroundColor: "#fff"}}>

        <Box sx={{height: 65, backgroundColor: "#202020"}}>
        </Box>
          
          <div style={{margin: "auto", width: "95%"}}>
          <Grid sx={{
                color: "black", 
                backgroundColor: '#fff', 
                height: "80px", 
                margin: "0",
                marginTop: "-70px",
                borderRadius: "15px",
                boxShadow: "0px 4px 5px 0px rgba(148,148,148,1)"
            }}>
                <Grid container spacing={2} sx={{marginTop: 0}}>
                    <Grid item xs={6}>
                        <div style={{maxWidth: "170px", paddingLeft: "25px", paddingTop: "5px"}}>
                            <img onClick={() => {window.location.href = "https://maqcars.co.uk"}} src={Logo} width="160"/>
                        </div>
                    </Grid>

                    <Grid item xs={6} sx={{marginTop: "5px", textAlign: "right", paddingRight: "20px"}}>
                            {!showMenu ?
                            <IconButton onClick={() => setShowMenu(true)}>
                                <FontAwesomeIcon fontSize={24} icon={faBars}/>
                            </IconButton>
                            :
                            <IconButton onClick={() => setShowMenu(false)}>
                                <FontAwesomeIcon fontSize={24} icon={faClose}/>
                            </IconButton>
                            }   
                    </Grid>
                </Grid>

                {showMenu ?
                <Box sx={{marginTop: 0, marginLeft: 0, textAlign: 'left', backgroundColor: '#fff', boxShadow: "0px 8px 5px 0px rgba(148,148,148,1)", borderRadius: "0px 0px 15px 15px"}}>
                    <Box sx={{ padding: "15px", backgroundColor: '#fff', borderBottom: "1px solid gray"}}>
                        <Link href="https://maqcars.co.uk/chauffeur/" sx={{textDecoration: "none"}}>
                            <Typography sx={{fontFamily: "Khula", color: "#6D6D6D"}}>Chauffeur</Typography>
                        </Link>
                    </Box>
                    <Box sx={{ padding: "15px", backgroundColor: '#fff', borderBottom: "1px solid gray"}}>
                        <Link href="https://maqcars.co.uk/private-hire/" sx={{textDecoration: "none"}}>
                            <Typography sx={{fontFamily: "Khula", color: "#6D6D6D"}}>Private hire</Typography>
                        </Link>
                    </Box>
                    <Box sx={{ padding: "15px", backgroundColor: '#fff'}}>
                        <Link href="https://maqcars.co.uk/contact/" sx={{textDecoration: "none"}}>
                            <Typography sx={{fontFamily: "Khula", color: "#6D6D6D"}}>Contact</Typography>
                        </Link>
                    </Box>
                </Box> : null }
             </Grid>

            </div>

        </AppBar>
      </Box>
    )

}


export default MobileHeader;
import { useFormContext, useWatch} from "react-hook-form";
import { Box, FormLabel, Grid} from "@mui/material";
import { AddressComponent, FormValues, Place, TripRoute } from "../../types";
import { useEffect, useState } from "react";
import VehicleSelect from "./VehicleSelect";
import { EndpointProvider } from "../../utils/EndpointProvider";
import DateField from "./DateField";
import TimeField from "./TimeField";
import PickupField from "./PickupField";
import DropoffField from "./DropoffField";
import PassengersNumberField from "./PassengersNumberField";
import LuggageNumberField from "./LuggageNumberField";
import { CalculateMatrix } from "../../utils/quote/CalculateMatrix";
import { hasLocationDataChanged, hasSubmissionDataChanged } from "../../utils/quote/FormHelpers";

interface Props {
    gMapsApiStatus: boolean;
    setTripRoute(route: TripRoute) : void;
    tripRoute: TripRoute;
}

export interface LastSubmissionData {
    date: string;
    vehicleId: string;
    pickup: Place;
    dropoff: Place;
}

const Form = ({gMapsApiStatus, tripRoute, setTripRoute}: Props) => {

    const { control, handleSubmit } = useFormContext();

    const [lastSubmission, setLastSubmission] = useState<LastSubmissionData | null>(null)

    const selectedVehicleIdWatch = useWatch({
        control,
        name: "vehicleId"
    });

    useEffect(() => {
        setTripRoute({
            ...tripRoute,
            cost: null
        });
    }, [selectedVehicleIdWatch])



    const onSubmit = async (formData: any) => {
        if(hasSubmissionDataChanged(lastSubmission, formData)){
            const endpointUrl = EndpointProvider("api/getCost");
            
            let distance = tripRoute.distance;
            let duration = tripRoute.duration;

            if(hasLocationDataChanged(lastSubmission, formData)){
                const matrix = await CalculateMatrix(formData.date, tripRoute);
                distance = matrix.distance;
                duration = matrix.duration;
            }
                
            let requestUrl = `${endpointUrl}/?distance=${distance}
                            &id=${formData.vehicleId}
                            &date=${formData.date}
                            &origin=${formData.pickup}
                            &destination=${formData.dropoff}`;
                            
            if(tripRoute?.destination?.types.includes("airport")){
                requestUrl += `&placeType=airport&airportName=${tripRoute.destination.name}&journeyType=dropoff`;
            }
            if(tripRoute?.origin?.types.includes("airport")){
                requestUrl += `&placeType=airport&airportName=${tripRoute.origin.name}&journeyType=pickup`;
            }
    
            fetch(requestUrl)
            .then(response => response.json())
            .then((cost) => {
                setTripRoute({
                    ...tripRoute,
                    distance: distance,
                    duration: duration,
                    cost: cost
                });
                setLastSubmission({date: formData.date, pickup: formData.pickup, dropoff: formData.dropoff, vehicleId: formData.vehicleId});
            });

        }
        
    }
      
    return (
        <Box>
            <form id="journey-form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <DateField tripRoute={tripRoute} setTripRoute={setTripRoute} />
                    </Grid>
                    <Grid item xs={6}>
                        <TimeField />
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <PickupField gMapsApiStatus={gMapsApiStatus} tripRoute={tripRoute} setTripRoute={setTripRoute} />
                </Grid>
                <Grid container spacing={1}>
                    <DropoffField gMapsApiStatus={gMapsApiStatus} tripRoute={tripRoute} setTripRoute={setTripRoute} />
                </Grid>
    
                <Grid container spacing={1} sx={{ marginTop: "10px"}}>
                    <Grid item xs={6}>
                        <PassengersNumberField />
                    </Grid>
                    <Grid item xs={6}>
                        <LuggageNumberField />
                    </Grid>
                </Grid>

                <Box sx={{width: '100%', textAlign: 'left', marginTop: "5px"}}>
                    <FormLabel>Vehicle type</FormLabel>
                </Box>
                <Grid container sx={{marginTop: "-5px"}}>
                    <VehicleSelect />
                </Grid>
            </form>
        </Box>
    );
};

export default Form;
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormContext } from "react-hook-form";
import moment from "moment";

const TimeField = () => {

    const { register, formState: { errors }, setValue } = useFormContext();
    const [time, setTime] = useState<Dayjs | null>(null);

    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                label="Time"
                value={time}
                onChange={(val) => {
                    setTime(val);
                    const formattedTime = moment(dayjs(val).toDate()).format("HH:mm");
                    setValue("time", formattedTime);
                }}
                renderInput={(params) => 
                    <TextField 
                        {...params} 
                        fullWidth={true}
                        {...register("time", { required: true })}
                        error={errors.time !== undefined}
                        helperText={errors.time?.message !== undefined ? "Required" : ""}
                    />
                }
            />
        </LocalizationProvider>
    )

}

export default TimeField;
import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Page from './Page';
import MobileHeader from './components/MobileHeader';


function App() {
  
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);


  return (
    <div className="App">
      {
        width > 1100 ? <Header /> : <MobileHeader />
      }
      <Page />
    </div>
  );
}

export default App;
